<template>
    <div class="application-container">
        <Aside/>
        <main>
            <h1>Cartera de Usuario</h1>
            <div class="info-card">
                <h3>Datos del Usuario</h3>
                <hr>
                <div class="row mb-2">
                    <div class="col-md-3"><p>Código</p></div>
                    <div class="col-md-9"><b>{{user.id}}</b></div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3"><p>Nombre</p></div>
                    <div class="col-md-9"><b>{{user.name + ' ' + user.last_name}}</b></div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3"><p>Documento</p></div>
                    <div class="col-md-9"><b>{{user.id_card}}</b></div>
                </div>
            </div>
            <div class="insights">
                <div class="commissions">
                    <span class="material-icons-sharp">payments</span>
                    <div class="middle">
                        <div class="left">
                            <h3>Dinero Pendiente</h3>
                            <h1>${{this.formatMoney(wallet.current_money)[0]}},<label class="fs-4">{{this.formatMoney(wallet.current_money)[1]}}</label></h1>
                        </div>
                    </div>
                </div>
                <div v-if="session_user.pages.payment" @click="showPayments()">
                    <span class="material-icons-sharp">receipt_long</span>
                    <div class="middle">
                        <div class="left">
                            <h3>Retiros</h3>
                            <h1>{{wallet.num_paid}}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-card">
                <table class="table table-striped" id="table-commissions-user" style="border:none">
                    <thead>
                        <tr>
                            <th colspan="4"><center>COMISIONES</center></th>
                        </tr>
                        <tr>
                            <th>Punto de Venta</th>
                            <th>Patrocinador</th>
                            <th>Ganancia</th>
                            <th>Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>  
        </main>
        <Right/>
        <!--------------MODAL PAGOS----------------->
        <div class="modal fade" id="modal-payments" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">
                            PAGOS COBRADOS
                        </h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-striped" id="table-payments" style="border:none">
                            <thead>
                                <tr>
                                    <th>Monto</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import Aside from '../components/Aside.vue';
import Right from '../components/Right.vue';

export default {
    name: 'UserWallet',
    components: {
        Aside,
        Right,
    },
    methods: {
        showPayments(){
            $('#modal-payments').modal('show');
            this.searchPayments({user_id:this.$route.params.id}).then(res => {
                let new_array = [];
                res.data.forEach(element => {
                    let commission = `$${this.formatMoney(element.money)[0]},<label style="font-size: .8rem;">${this.formatMoney(element.money)[1]}</label>`;
                    let date = this.formatHourDate(element.created_at);
                    let json = {
                        commission: commission,
                        created_at: date
                    }
                    new_array.push(json);
                });
                $('#table-payments').DataTable({
                    responsive: true,
                    autoWidth: false,
                    lengthChange: false,
                    info: false,
                    destroy: true,
                    data: new_array,
                    columns: [
                        {"data": "commission"},
                        {"data": "created_at"},
                    ]
                });
            })
        },  
        showCommissionsUser(){
            this.searchCommissions({user_id: this.$route.params.id}).then(res => {
                let new_array = [];
                res.data.forEach(element => {
                    let commission = `$${this.formatMoney(element.money)[0]},<label style="font-size: .8rem;">${this.formatMoney(element.money)[1]}</label>`;
                    let date = this.formatDate(element.created_at);
                    let json = {
                        sale_point_id: element.sale_point_id,
                        sale_point_name: element.sale_point_name,
                        client_user_id: element.client_user_id,
                        client_user_name: element.client_user_name,
                        commission: commission,
                        created_at: date
                    }
                    new_array.push(json);
                });
                $("#table-commissions-user").DataTable({
                    responsive: true,
                    autoWidth: false,
                    lengthChange: false,
                    info: false,
                    destroy: true,
                    data: new_array,
                    columns: [
                        {"data": "sale_point_name"},
                        {"data": "client_user_name"},
                        {"data": "commission"},
                        {"data": "created_at"},
                    ]
                });
            }).catch(err => console.log(err.response))
        },
        formatMoney(number){
            let money = new Intl.NumberFormat('em-IN').format(number);
            let split = money.split(',');
            let decimals = split[1];
            if(!decimals) decimals = '00';
            return [split[0], decimals];
        },
        formatDate(date){
            var fecha = new Date(date);
            var ano = fecha.getFullYear();
            var mes = fecha.getMonth()+1;
            var dia = fecha.getDate();
            if(mes < 10)  mes = "0" + mes
            if(dia < 10) dia = "0" + dia
            return dia+"/"+mes+"/"+ano;
        },
        formatHourDate(date){
            var fecha = new Date(date);
            var ano = fecha.getFullYear();
            var mes = fecha.getMonth()+1;
            var dia = fecha.getDate();
            var horas = fecha.getHours();
            var minutos = fecha.getMinutes();

            if(mes < 10){
                mes="0"+mes
            }
            if(dia < 10 ){
                dia="0"+dia
            }
            return `${ano}-${mes}-${dia} ${horas}:${minutos}`;
        },
        ...mapActions("user", ["getUsersLine"]),
        ...mapMutations(['loadSessionUser']),
        ...mapActions("commission", ["searchCommissions"]),
        ...mapActions("commission", ["searchPayments"]),
        ...mapMutations("user", ["loadUser"]),
        ...mapMutations("user", ["loadWallet"]),
    },
    mounted: function(){
        $('.modal-backdrop').remove();
        this.loadSessionUser();
        this.loadUser(this.$route.params.id);
        this.loadWallet(this.$route.params.id);
        this.showCommissionsUser();
        
    },
    computed: {
        ...mapState(["session_user"]),
        ...mapState("user", ["user"]),
        ...mapState("user", ["wallet"]),
    }
}
</script>